import * as React from "react";
import PageWrapper from "../../components/pages/PageWrapper";
import SignCard from "../../components/pages/sign/SignCard";
import {Form, Input, message} from "antd";
import SmsInput from "../../components/pages/sign/SmsInput";
import Seo from "../../components/seo";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import EmailInput from "../../components/pages/sign/EmailInput";
import jsonRequest from "../../utils/request/jsonRequest";
import auth from "../../utils/auth";

const SignIn = ({location}) => {

  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [account, setAccount] = useState('')
  const [type, setType] = useState<'phone'|'email'>('phone')

  useEffect(() => {
    if (account.match(/^.*@.*$/)) {
      setType('email')
    } else {
      setType('phone')
    }
  }, [account])

  const onValuesChange = values => {
    if ('account' in values) {
      setAccount(values.account)
    }
  }

  const onFinish = values => {
    const data = {...values}
    data[type] = account
    const url = type == 'phone' ?
      '/login/phone/code' :
      '/login/email/code'
    jsonRequest(url, {
      method: 'POST',
      data,
    }).then(rsp => {
      if (rsp.success) {
        message.success("登录成功")
        auth.setToken(rsp.data.data.token)
        auth.redirect()
      } else {
        message.error(rsp.message)
      }
    }).catch(console.error)
  }

  return (<>
    <PageWrapper>
      <SignCard
        title='验证码登录'
        buttonText="登录"
        location={location}
        inputs={(
          <Form
            {...{onValuesChange, onFinish, form}}
          >
            <Form.Item name='account' required={false} rules={[{required: true, message: '请输入手机号或邮箱'}]}>
              <Input size="large" placeholder="请输入手机号或邮箱" />
            </Form.Item>
            <Form.Item name='code' required={false} rules={[{required: true, message: '请输入验证码'}]}>
              {
                type == 'phone' && (
                  <SmsInput phone={account} url='/login/phone/send'/>
                ) || (
                  <EmailInput email={account} url='/login/email/send'/>
                )
              }
            </Form.Item>
          </Form>
        )}
        links={[
          {
            href: "/sign/in",
            text: '密码登录',
          },
          {
            href: "/sign/on",
            text: '注册账号'
          }
        ]}
        onSubmit={form.submit}
      >

      </SignCard>
    </PageWrapper>
  </>)
}

export default SignIn

export const Head = () => <Seo title='GitWork-账号登录'/>
