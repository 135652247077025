import request, {Init} from "./index";


const jsonRequest = (input: RequestInfo | URL, init?: Init ) => {
  if (init?.data) {
    const data = init.data;
    Object.keys(data).forEach(key => {
      if (data[key] === null || data[key] === undefined) {
        delete data[key];
      }
    })
    init.data = data;
  }
  return new Promise<{ response: Response, data: any, success: boolean, message: string; }>((resolve, reject) => {
    request(input, init).then(rsp => {
      if (rsp.ok) {
        rsp.json().then(rspData => {
          if (rspData.success) {
            resolve({response: rsp, data: rspData, success: true, message: ''})
          } else {
            resolve({response: rsp, data: rspData, message: rspData.message, success: false,})
          }
        }).catch(reject)
      } else { // 处理错误
        resolve({response: rsp, data: {}, success: false, message: '网络错误'})
      }
    }).catch(reject)
  });
}

export default jsonRequest
